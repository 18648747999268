.Footer {
    position: relative;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    outline: gray ridge thin;
}

.copyright-box {
    display: flex;
    justify-self: start;
    align-items: center;
    margin-left: 30px;
    padding-bottom: 7px;

}

.imprint-box {
    display: flex;
    align-items: center;
    justify-content: center
}


.imprint {
    justify-self: center;
    text-align: center;
    text-decoration: none;
    color: dodgerblue;
    padding-bottom: 7px;

}

.social-media-box {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-right: 30px;

}

.social-container {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, auto);
    grid-gap: 10px;
    justify-content: end;
    list-style: none;
    padding-top: 8px;
}


.social-links {

}