.navbar{
    outline: gray ridge thin;

}
.navbar-brand > h1:nth-child(1) {
    margin-left: 30px;
}
.Container{

}
/* Menu icon styling*/
.navbar-light .navbar-toggler {
    color: dodgerblue !important;
    border-color: dodgerblue !important;
    margin-right: 30px;
}
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}
.navbar-nav {
    margin-right: 60px;
    grid-gap: 40px;
    color: dodgerblue;
    font-size: 25px;
    margin-left: 60px;
    align-items: center;
}

.nav-link{
    text-decoration: none;
    color: dodgerblue !important;
    display: block;
}
.nav-link:hover{
    transform: scale(1.3);
    color:black !important;
}
.nav-link.active{
    transform: scale(1.3);
    text-decoration: underline;
}
