@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: url('fonts/montserrat-v24-latin-200.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('fonts/montserrat-v24-latin-200.eot') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/montserrat-v24-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
  url('fonts/montserrat-v24-latin-200.woff') format('woff'), /* Modern Browsers */
  url('fonts/montserrat-v24-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
  url('fonts/montserrat-v24-latin-200.svg#Montserrat') format('svg'); /* Legacy iOS */
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}
.Main{
  min-height: 100vh;
}
