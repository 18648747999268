@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: url(/static/media/montserrat-v24-latin-200.701cc5a8.eot); /* IE9 Compat Modes */
  src: local(''),
  url(/static/media/montserrat-v24-latin-200.01307365.woff2) format('embedded-opentype'), 
  url(/static/media/montserrat-v24-latin-200.01307365.woff2) format('woff2'), 
  url(/static/media/montserrat-v24-latin-200.0cda8b0e.woff) format('woff'), 
  url(/static/media/montserrat-v24-latin-200.3171a22b.ttf) format('truetype'), 
  url(/static/media/montserrat-v24-latin-200.6628b783.svg#Montserrat) format('svg'); /* Legacy iOS */
}

:root {
  --bg-color: #fff;
  --primary-color: black;
  --secondary-color: dodgerblue;
  --text-color: #black;
  --text-color-2: #fff;
  --overlay-color: rgb(12 12 12 / 63%);
}

[data-theme="light"] {
  --bg-color: #fff;
  --primary-color: black;
  --secondary-color: dodgerblue;
  --text-color: #black;
  --text-color-2: #fff;
  --overlay-color: rgb(12 12 12 / 63%);
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: visible;
  color: #black;
  color: var(--text-color);
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
}
a{
  -webkit-text-decoration: dodgerblue;
          text-decoration: dodgerblue;
}

p {
  word-break: break-word;
  -webkit-hyphens: auto;
          hyphens: auto;
}

.ovhidden {
  overflow: hidden;
}

.text_2,
.text_2:hover {
  color: #fff;
  color: var(--text-color-2);
}

code {
  font-family: 'Montserrat', monospace;
}

.cursor__dot div {
  z-index: 999999 !important;
}

.cursor__dot div:last-child {
  -webkit-filter: invert(1);
          filter: invert(1);
  background-color: black !important;
  background-color: var(--primary-color) !important;
}

.cursor__dot div:first-child {
  -webkit-filter: invert(1);
          filter: invert(1);
  background-color: rgb(12 12 12 / 63%) !important;
  background-color: var(--overlay-color) !important;
}

.color_pr {
  color: black !important;
  color: var(--primary-color) !important;
}

.color_sec {
  color: dodgerblue;
  color: var(--secondary-color);
}

.contact__form .form-control {
  padding: 1.375rem .75rem;
  line-height: 1.5;
  color: #black;
  color: var(--text-color);
  background-color: #fff;
  background-color: var(--bg-color);
  border-radius: 0 !important;
  border: 1px solid dodgerblue;
  border: 1px solid var(--secondary-color);
  resize: none;
  width: 100%;
  height: 100%;
}

.contact__form input.form-control {
  margin-bottom: 2em;
  height: calc(2.5em + .75rem + 2px);
}

.loading-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  z-index: 999999999;
  background: #black;
  background: var(--text-color);
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  -webkit-animation: shift-rightwards 1s ease-in-out infinite;
          animation: shift-rightwards 1s ease-in-out infinite;
  -webkit-animation-delay: .3s;
          animation-delay: .3s;
}

@-webkit-keyframes shift-rightwards {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  40% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  60% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

@keyframes shift-rightwards {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  40% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  60% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}


@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: url(/static/media/montserrat-v24-latin-200.701cc5a8.eot); /* IE9 Compat Modes */
  src: local(''),
  url(/static/media/montserrat-v24-latin-200.701cc5a8.eot) format('embedded-opentype'), 
  url(/static/media/montserrat-v24-latin-200.01307365.woff2) format('woff2'), 
  url(/static/media/montserrat-v24-latin-200.0cda8b0e.woff) format('woff'), 
  url(/static/media/montserrat-v24-latin-200.3171a22b.ttf) format('truetype'), 
  url(/static/media/montserrat-v24-latin-200.6628b783.svg#Montserrat) format('svg'); /* Legacy iOS */
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}
.Main{
  min-height: 100vh;
}

.navbar{
    outline: gray ridge thin;

}
.navbar-brand > h1:nth-child(1) {
    margin-left: 30px;
}
.Container{

}
/* Menu icon styling*/
.navbar-light .navbar-toggler {
    color: dodgerblue !important;
    border-color: dodgerblue !important;
    margin-right: 30px;
}
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}
.navbar-nav {
    margin-right: 60px;
    grid-gap: 40px;
    color: dodgerblue;
    font-size: 25px;
    margin-left: 60px;
    align-items: center;
}

.nav-link{
    text-decoration: none;
    color: dodgerblue !important;
    display: block;
}
.nav-link:hover{
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    color:black !important;
}
.nav-link.active{
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    text-decoration: underline;
}

.Footer {
    position: relative;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    outline: gray ridge thin;
}

.copyright-box {
    display: flex;
    justify-self: start;
    align-items: center;
    margin-left: 30px;
    padding-bottom: 7px;

}

.imprint-box {
    display: flex;
    align-items: center;
    justify-content: center
}


.imprint {
    justify-self: center;
    text-align: center;
    text-decoration: none;
    color: dodgerblue;
    padding-bottom: 7px;

}

.social-media-box {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-right: 30px;

}

.social-container {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, auto);
    grid-gap: 10px;
    justify-content: end;
    list-style: none;
    padding-top: 8px;
}


.social-links {

}
.vertical-timeline-element-content p{
    font-size: 20px !important;
}

.image-holder{
    width:100%;
}
img {
    width:100%;
    height:100%;
    object-fit:cover;
    align-self: center;
}
.smooth-image {
    transition: opacity 1s;
}
.image-visible {
    opacity: 1;
}
.image-hidden {
    opacity: 0;
}
.p {
    text-align: center;
    width: 90%;

}

