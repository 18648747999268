@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: url('fonts/montserrat-v24-latin-200.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('fonts/montserrat-v24-latin-200.woff2') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/montserrat-v24-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
  url('fonts/montserrat-v24-latin-200.woff') format('woff'), /* Modern Browsers */
  url('fonts/montserrat-v24-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
  url('fonts/montserrat-v24-latin-200.svg#Montserrat') format('svg'); /* Legacy iOS */
}

:root {
  --bg-color: #fff;
  --primary-color: black;
  --secondary-color: dodgerblue;
  --text-color: #black;
  --text-color-2: #fff;
  --overlay-color: rgb(12 12 12 / 63%);
}

[data-theme="light"] {
  --bg-color: #fff;
  --primary-color: black;
  --secondary-color: dodgerblue;
  --text-color: #black;
  --text-color-2: #fff;
  --overlay-color: rgb(12 12 12 / 63%);
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: visible;
  color: var(--text-color);
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
}
a{
  text-decoration: dodgerblue;
}

p {
  word-break: break-word;
  hyphens: auto;
}

.ovhidden {
  overflow: hidden;
}

.text_2,
.text_2:hover {
  color: var(--text-color-2);
}

code {
  font-family: 'Montserrat', monospace;
}

.cursor__dot div {
  z-index: 999999 !important;
}

.cursor__dot div:last-child {
  filter: invert(1);
  background-color: var(--primary-color) !important;
}

.cursor__dot div:first-child {
  filter: invert(1);
  background-color: var(--overlay-color) !important;
}

.color_pr {
  color: var(--primary-color) !important;
}

.color_sec {
  color: var(--secondary-color);
}

.contact__form .form-control {
  padding: 1.375rem .75rem;
  line-height: 1.5;
  color: var(--text-color);
  background-color: var(--bg-color);
  border-radius: 0 !important;
  border: 1px solid var(--secondary-color);
  resize: none;
  width: 100%;
  height: 100%;
}

.contact__form input.form-control {
  margin-bottom: 2em;
  height: calc(2.5em + .75rem + 2px);
}

.loading-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  z-index: 999999999;
  background: var(--text-color);
  transform: translateX(100%);
  animation: shift-rightwards 1s ease-in-out infinite;
  animation-delay: .3s;
}

@keyframes shift-rightwards {
  0% {
    transform: translateX(-100%);
  }
  40% {
    transform: translateX(0%);
  }
  60% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}

