.image-holder{
    width:100%;
}
img {
    width:100%;
    height:100%;
    object-fit:cover;
    align-self: center;
}
.smooth-image {
    transition: opacity 1s;
}
.image-visible {
    opacity: 1;
}
.image-hidden {
    opacity: 0;
}
.p {
    text-align: center;
    width: 90%;

}